<template>
	<div class="customer">
		<div v-if="lang=='zh'">
			BDO 银行账号 <br>
			银行：BDO<br>
			名字：YEAH FRESH GROUP INC<br>
			账号：004560030521<br><br>
		</div>
		<div v-if="lang=='en'">
			BDO BANK ACCOUNT<br>
			BANK NAME：BDO<br>
			NAME:YEAH FRESH GROUP INC<br>
			ACCOUNT：004560030521<br><br>
		</div>
		<div v-if="lang=='vie'">
			TÀI KHOẢN NGÂN HÀNG BDO<br>
			TÊN NGÂN HÀNG:BDO<br>
			TÊN:YEAH FRESH GROUP INC<br>
			TÀI KHOẢN：004560030521<br><br>
		</div>
		<div v-if="lang=='zh'">
			Gcash 转账账号<br> gcash：09610098888<br><br>
		</div>
		<div v-if="lang=='en'">
			Gcash Account<br> gcash：09610098888<br><br>
		</div>
		<div v-if="lang=='vie'">
			Tài khoản Gcash<br> gcash：09610098888<br><br>
		</div>
		<img src="@/assets/gcashTu.png" alt="" style="width: 200px;margin-bottom: 20px;">
		<div v-if="lang=='zh'">
			九鼎账号<br>
			户名: OR4622 用户名:Max888<br><br>
		</div>
		<div v-if="lang=='en'">
			9 Dynasty account <br>
			Account name: OR4622 User name: Max888<br><br>
		</div>
		<div v-if="lang=='vie'">
			9 Dynasty tài khoản<br>
			Tên tài khoản: OR4622 Tên người dùng: Max888<br><br>
		</div>

		<div v-if="lang=='zh'">转账成功后 请提供转账明细联系客服为您核实<br><br></div>
		<div v-if="lang=='en'">
			Please provide the transfer voucher and contact customer service for verification.<br><br>
		</div>
		<div v-if="lang=='vie'">
			Sau khi chuyển khoản thành công, vui lòng cung cấp chứng từ chuyển khoản và liên hệ với bộ phận chăm sóc
			khách
			hàng để xác minh.<br><br>
		</div>

		<div v-if="lang=='zh'">客服联系方式:<br></div>
		<div v-if="lang!='zh'">Contact CS</div>
		
		<div v-if="lang=='zh'">微信：yeahfresh522<br></div>
		<!-- <div v-if="lang!='zh'">WeChat：yeahfresh522<br><br></div> -->
		
		

		<div v-if="lang=='zh'">联系电话：+63 9218638813<br></div>
		<div v-if="lang=='en'">Text/call：+63 9218638813<br></div>
		<div v-if="lang=='vie'">Nhắn tin/gọi điện：+63 9218638813<br><br></div>
		telegram：yeahfresh522<br>


	</div>
</template>

<script>
	export default {
		name: "recharge",
		data() {
			return {

			}
		},

		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.customer {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: scroll;
		padding-top: 30px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		box-sizing: border-box;
	}
</style>
